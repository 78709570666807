body {
  margin: 0; /* Eliminar margen predeterminado del cuerpo */
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.Login {
    font-family: "Poppins";
    font-weight: 300;
    font-style: normal;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
  
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@700&family=Montserrat:wght@300&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.Login-header {
  font-family: "Poppins";
  font-weight: 300;
  font-style: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.Login-input {
  margin: 1vmin;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.input-wrapper {
  position: relative;
  width: 100%;
}

.input {
  font-weight: bold;
  color: white;
  margin: 1vmin;
  border-color: white;
  background-color: black;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 10px 15px 10px 35px;
  width: 100%;
}

.input2 {
  font-weight: bold;
  color: white;
  margin: 1vmin;
  border-color: white;
  background-color: black;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 10px 15px 10px 5px;
  width: 100%;
}


.input-icon {
  color: white;
  position: absolute;
  width: 30px;
  height: 30px;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.container-inputs {
  width: 70%;
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}



.btnLogin {
  font-size: x-large !important;
  border-radius: 15px !important;
  margin-top: 1em !important;
  text-decoration: none !important;
  color:black !important;
  border-color: #ffffff !important;
  border-style: solid !important; 
  background-color: white !important;
  text-align: center !important;
  padding: 0;
  width: 100%;
}

.css-i4bv87-MuiSvgIcon-root {
    color: white;
}

.googleBtnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.googlebtn{
    background-color: rgb(255, 255, 255);
    display: inline-flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.54);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
    padding: 0px;
    border-radius: 2px;
    border: 1px solid transparent;
    font-size: 20px !important;
    font-weight: 500;
    border-radius: 5px !important;
    font-family: Roboto, sans-serif;
    text-align: center;
}


.btnLogin:hover {
  transition: .5s !important;
  color: white !important;
  background-color: #101010 !important;
}

  
.App-link {
  margin-top: 1%;
  font-size: medium;
  color: #484da1;
}
.App-link:hover {
  color: #6467a3;
}

.name{
  letter-spacing: 10px;
}

.title{
  font-size: 35px;
  letter-spacing: 5px;
  font-weight: 400;
}
.linea {
  margin:0px 20px;
  width:140px;    
  border-top:1px solid #ffffff;
  position: relative;
  top:10px;
  float:left;
}

.leyenda {
  float:left;
}

.content{
  width:400px;
  padding-top: 10%;
  margin-bottom: 20%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 720px) {
  .container-inputs {
    width: 360px;
  }
}

@media (max-width: 1100px) {
  .btnWhite {
    font-size: medium !important;
  }
}
  